// Legacy, maybe it's should be removed
import { useDropEvent } from 'root/src/client/logic/dropEvent/hooks/useDropEvent';
import { useCreatorFollowersCountByPlatform, useIsBrand, useUserCreatorData, } from 'root/src/client/v2/common/hooks/useUser';
import { maxDaresReachedMessage, notAbleToParticipateMessage } from 'root/src/shared/constants/dropEvent';
import { useIsMaxDaresReachedByAllUserPlatforms, } from 'root/src/client/logic/dropEvent/hooks/useIsMaxDaresReached';
import { getDaresPlatforms, useConnectedPlatformsByDares, } from 'root/src/client/logic/dropEvent/hooks/useConnectedPlatformsByDares';
import { providersLabelMap } from 'root/src/shared/constants/providers';
import { getMinimalRequirementsMessage } from 'root/src/client/logic/dropEvent/hooks/useDareRequirementMessages';
export function useDropCardRequirementsMessage(recordId) {
    const { averageCcv = 0, connectedPlatforms } = useUserCreatorData();
    const connectedPlatformsByDaresPlatform = useConnectedPlatformsByDares(recordId);
    const followersCountByPlatform = useCreatorFollowersCountByPlatform();
    const dropEvent = useDropEvent(recordId);
    const isMaxDaresReached = useIsMaxDaresReachedByAllUserPlatforms(recordId);
    const isBrand = useIsBrand();
    if (isBrand) {
        return { display: false };
    }
    if (!dropEvent) {
        return { display: false };
    }
    const { isAbleToParticipate, ccvBreakpoints, followersBreakpoints, dares, } = dropEvent;
    if (!connectedPlatforms.length) {
        return {
            display: false,
        };
    }
    if (!isAbleToParticipate) {
        return {
            display: true,
            canUserParticipate: false,
            requirements: [notAbleToParticipateMessage],
        };
    }
    if (isMaxDaresReached) {
        return {
            display: true,
            canUserParticipate: false,
            requirements: [maxDaresReachedMessage],
        };
    }
    if (!(ccvBreakpoints === null || ccvBreakpoints === void 0 ? void 0 : ccvBreakpoints.length) && !(followersBreakpoints === null || followersBreakpoints === void 0 ? void 0 : followersBreakpoints.length)) {
        return {
            display: true,
            canUserParticipate: true,
            requirements: [],
        };
    }
    if (!connectedPlatformsByDaresPlatform.length) {
        const missingPlatforms = getDaresPlatforms(dares).map(platform => providersLabelMap[platform]);
        return {
            display: true,
            canUserParticipate: false,
            requirements: [`You need connect ${missingPlatforms.join(' or ')} to participate in this drop.`],
        };
    }
    const minimalRequirementsMessages = getMinimalRequirementsMessage({
        dropEvent, averageCcv, followersCountByPlatform, connectedPlatformsByDaresPlatform,
    });
    return {
        display: true,
        canUserParticipate: !minimalRequirementsMessages,
        requirements: minimalRequirementsMessages
            ? [minimalRequirementsMessages]
            : [],
    };
}
