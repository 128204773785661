import { useConnectedPlatformsByDares, } from 'root/src/client/logic/dropEvent/hooks/useConnectedPlatformsByDares';
import { useUserCreatorData, useCreatorFollowersCountByPlatform, useIsAuthenticated, useIsBrand, } from 'root/src/client/v2/common/hooks/useUser';
import { useDropEvent } from 'root/src/client/logic/dropEvent/hooks/useDropEvent';
import { calculateMaxDaresByPlatform } from 'root/src/shared/util/calculateMaxDares';
import { Platform, DarePlatform } from 'root/src/shared/@types/platforms';
import { useMaxDaresByPlatform } from 'root/src/client/logic/dropEvent/hooks/useMaxDaresByPlatform';
import providers, { platformsLabelMap } from 'root/src/shared/constants/providers';
import { formatNumberWithCommas } from 'root/src/shared/util/formatNumberWithCommas';
const ccvRequiredOffset = 1;
export const useDareRequirementMessages = () => {
    const { connectedPlatforms, averageCcv = 0 } = useUserCreatorData();
    const dropEvent = useDropEvent();
    const isBrand = useIsBrand();
    const followersCountByPlatform = useCreatorFollowersCountByPlatform();
    const isAuthenticated = useIsAuthenticated();
    const connectedPlatformsByDaresPlatform = useConnectedPlatformsByDares();
    const maxDaresByUserPlatforms = useMaxDaresByPlatform({ platform: DarePlatform.Any });
    if (!dropEvent) {
        return [];
    }
    if (!dropEvent.dares.length) {
        return [];
    }
    if (!isAuthenticated) {
        return ['Sign in to get started'];
    }
    if (isBrand) {
        return [];
    }
    if (!connectedPlatforms.length) {
        return ['Connect a channel to get started.'];
    }
    if (maxDaresByUserPlatforms === 0) {
        const minimalRequirementsMessage = getMinimalRequirementsMessage({
            connectedPlatformsByDaresPlatform, averageCcv, dropEvent, followersCountByPlatform,
        });
        return [minimalRequirementsMessage];
    }
    return connectedPlatformsByDaresPlatform.map((platform) => {
        const maxDares = calculateMaxDaresByPlatform({
            followerCount: followersCountByPlatform[platform],
            averageCcv,
            platform,
            campaign: dropEvent,
        });
        return getDareMessageByPlatform({ maxDares, dropEvent, connectedPlatforms, platform });
    });
};
export function getDareMessageByPlatform({ platform, dropEvent, maxDares, connectedPlatforms, }) {
    var _a, _b;
    if (!dropEvent) {
        return null;
    }
    const isCcvBreakpoints = Boolean((_a = dropEvent.ccvBreakpoints) === null || _a === void 0 ? void 0 : _a.length);
    const isFollowersBreakpoints = Boolean((_b = dropEvent.followersBreakpoints) === null || _b === void 0 ? void 0 : _b.length);
    if (!connectedPlatforms.includes(platform)) {
        return null;
    }
    if (platform === providers.twitch && !isCcvBreakpoints) {
        return 'Based on your CCV, you can accept ALL Twitch dares. ';
    }
    if (platform === providers.youtube && !isFollowersBreakpoints /* TODO fix for just yt bp */) {
        return 'Based on your YouTube viewership, you can accept ALL YouTube dares. ';
    }
    if (platform === providers.tiktok && !isFollowersBreakpoints /* TODO fix for just tt bp */) {
        return 'Based on your TikTok viewership, you can accept ALL TikTok dares. ';
    }
    if (!isCcvBreakpoints && !isFollowersBreakpoints) {
        return null;
    }
    return `Based on your ${platformsLabelMap[platform]} viewership you can accept ${Number.isFinite(maxDares) ? maxDares : 'ALL'} ${platformsLabelMap[platform]} dares. `;
}
export function getMinimalRequirementsMessage({ dropEvent, connectedPlatformsByDaresPlatform, averageCcv, followersCountByPlatform }) {
    const { ccvBreakpoints, followersBreakpoints } = dropEvent;
    const minCcv = getMinCcvBreakpoint(ccvBreakpoints);
    const minYoutubeFollowers = getMinFollowersBreakpointByPlatform(followersBreakpoints, Platform.YouTube);
    const minTiktokFollowers = getMinFollowersBreakpointByPlatform(followersBreakpoints, Platform.TikTok);
    const userRequirementsMessages = connectedPlatformsByDaresPlatform
        .map((platform) => {
        if (platform === Platform.Twitch && minCcv && minCcv > (averageCcv || 0)) {
            return `${formatNumberWithCommas(minCcv)} Twitch CCV`;
        }
        if (platform === Platform.YouTube && minYoutubeFollowers && minYoutubeFollowers > followersCountByPlatform[platform]) {
            return `${formatNumberWithCommas(minYoutubeFollowers)} YouTube Subscribers`;
        }
        if (platform === Platform.TikTok && minTiktokFollowers && minTiktokFollowers > followersCountByPlatform[platform]) {
            return `${formatNumberWithCommas(minTiktokFollowers)} TikTok Followers`;
        }
        return null;
    })
        .filter(item => item);
    const canUserParticipate = userRequirementsMessages.length !== connectedPlatformsByDaresPlatform.length;
    if (!canUserParticipate) {
        return `You need at least ${userRequirementsMessages.join(' or ')} to participate in this drop.`;
    }
    return null;
}
export function getMinFollowersBreakpointByPlatform(followerBreakpoints, platform) {
    const followerBreakpointsByPlatform = followerBreakpoints === null || followerBreakpoints === void 0 ? void 0 : followerBreakpoints.filter(breakpoint => breakpoint.platform === platform).map(({ followerCount }) => followerCount);
    if (!(followerBreakpointsByPlatform === null || followerBreakpointsByPlatform === void 0 ? void 0 : followerBreakpointsByPlatform.length)) {
        return null;
    }
    return Math.min(...followerBreakpointsByPlatform);
}
export function getMinCcvBreakpoint(ccvBreakpoints) {
    const ccvCountBreakpoints = ccvBreakpoints === null || ccvBreakpoints === void 0 ? void 0 : ccvBreakpoints.map(({ ccv }) => ccv);
    if (!(ccvCountBreakpoints === null || ccvCountBreakpoints === void 0 ? void 0 : ccvCountBreakpoints.length)) {
        return null;
    }
    return Math.min(...ccvCountBreakpoints) + ccvRequiredOffset;
}
