import { useRecordContext } from 'root/src/client/web/contexts/recordContext'
import { faqAccordionIds } from 'root/src/shared/constants/accordionIds'
import { useRouter } from 'next/router'
import { useDropEvent } from 'root/src/client/logic/dropEvent/hooks/useDropEvent'
import { useMaxDaresByPlatform } from 'root/src/client/logic/dropEvent/hooks/useMaxDaresByPlatform'
import { useDropCardRequirementsMessage } from 'root/src/client/logic/dropEvent/hooks/useDropCardRequirementsMessage'

export const useDropEventExtraData = () => {
	const { recordId: dropEventId } = useRecordContext()
	const dropEventAccessibility = useDropCardRequirementsMessage(dropEventId)

	const dropEvent = useDropEvent(dropEventId)
	const maxDares = useMaxDaresByPlatform({ dropEvent })
	const acceptedDares = dropEvent?.dares?.filter(dare => dare.status)

	return {
		dropEventAccessibility,
		maxDares,
		acceptedDares,
	}
}

export const useDropEventCard = () => {
	const { recordId: dropEventId } = useRecordContext()
	const dropEvent = useDropEvent(dropEventId) || {}

	const extraData = useDropEventExtraData()

	const router = useRouter()

	const onOpenFaqSection = (evt) => {
		evt.preventDefault()
		router.push(`/e/${dropEvent.mnemonicId}#${faqAccordionIds.WHAT_DO_I_NEED_TO_PARTICIPATE}`)
	}

	return {
		dropEvent,
		extraData,
		onOpenFaqSection,
	}
}

/**
 * @typedef {Object} DropEvent
 * @property {string} id
 * @property {string} mnemonicId
 * @property {string} campaignName
 * @property {string} campaignProduct
 * @property {Brand} brand
 * @property {string[]} genres
 * @property {{ platform: string }[]} initialProducts
 * @property {boolean} isPrivate
 * @property {{ image: string, label: string }} gameObj
 */

/**
 * @typedef {Object} Brand
 * @property {string} id
 * @property {string} brandName
 * @property {string} brandSmallLogo
 */
