import { memo } from 'react'
import * as BlackCard from 'root/src/client/v2/common/components/BlackCard/BlackCard'
import { useBlackCardContext } from 'root/src/client/v2/common/components/BlackCard/hooks/useBlackCardContext'
import providers, {
	darePlatformsMap,
} from 'root/src/shared/constants/providers'
import { useDropEventCard } from 'root/src/client/web/list/listItemComponents/dropEvent/hooks/useDropEventCard'
import classNames from 'classnames'
import { platformIconMap } from 'root/src/shared/constants/dropKeys'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withProjectCardStyle } from 'root/src/client/web/list/listItemComponents/style'
import {
	PlatformIcon,
	PlatformIconColor,
} from 'root/src/client/web/base/CustomButton/PlatformIcon'

const getDropEventPlatforms = ({ dares = [] }) => {
	// filter out dares with platform
	const platformDares = (dares).flatMap((dare) => {
		if (dare.platform) {
			return dare.platform
		}

		return []
	})

	// if it's equal to length of dares, it means, every dare has specified platform
	if (platformDares.length === dares.length) {
		return [...new Set(platformDares)]
	}

	return [...new Set(darePlatformsMap)]
}

export const DarePlatformsSection = withProjectCardStyle(({ classes, platforms }) => {
	const darePlatforms = platforms?.includes(providers.any)
		? darePlatformsMap
		: platforms

	return (
		<ul className={classes.dropPlatforms}>
			{darePlatforms.map(platform => (
				<li
					key={platform}
					className={classes.platformIconContainer}
				>
					<PlatformIcon color={PlatformIconColor.Dark} size="xs" platform={platform} />
				</li>
			))}
		</ul>
	)
})

const DropPreview = withProjectCardStyle(({ classes }) => {
	const { dropEvent } = useDropEventCard()
	const dropTitle = dropEvent?.dropTitle || dropEvent?.gameObj?.label || dropEvent?.campaignProduct
	const dropPlatforms = getDropEventPlatforms(dropEvent)

	return (
		<div className={classNames(classes.footerBody, classes.cardPreview)}>
			<div>
				<div className={classes.footerHeaderContainer}>
					<BlackCard.Caption title={dropTitle} />
				</div>
				<div className={classNames(
					classes.descriptionContainer,
					classes.descriptionContainerClamp6,
				)}
				>
					<p className={classes.descriptionText}>
						{dropEvent.gameDescriptionBody}
					</p>
				</div>
				<div style={{ marginTop: 10 }}>
					<DarePlatformsSection platforms={dropPlatforms} />
				</div>
			</div>
		</div>
	)
})

const GamePlatform = ({ platform }) => {
	const Icon = platformIconMap[platform]

	if (!Icon) {
		return null
	}

	return <FontAwesomeIcon size="2xl" icon={Icon} />
}

const GamePlatforms = withProjectCardStyle(({ classes, platforms }) => (
	<ul className={classes.gamePlatformsList}>
		{platforms.map(platform => (
			<li key={platform}>
				<GamePlatform platform={platform} />
			</li>
		))}
	</ul>
))

const DropDetails = withProjectCardStyle(({ classes }) => {
	const { dropEvent } = useDropEventCard()

	const genres = dropEvent?.genres?.join(', ')
	const gamePlatforms = [...new Set(
		dropEvent?.initialProduct?.map(initialProduct => initialProduct.platform),
	)]

	return (
		<div className={classNames(classes.footerBody, classes.cardDetails)}>
			{Boolean(genres?.length) && (
				<BlackCard.HighlightedSection
					title="Genres"
					text={<span className={classes.descriptionText}>{genres}</span>}
				/>
			)}
			{Boolean(gamePlatforms?.length) && (
				<BlackCard.HighlightedSection
					title="Game platforms"
					text={<GamePlatforms platforms={gamePlatforms} />}
				/>
			)}
		</div>
	)
})

export const DropEventBody = memo(() => {
	const { isHovered } = useBlackCardContext()

	if (isHovered) {
		return <DropDetails />
	}

	return <DropPreview />
})
