export const faqAccordionIds = {
    WHAT_ARE_DROPS: 'what_are_drops',
    WHAT_ARE_DARES: 'what_are_dares',
    WHAT_DO_I_NEED_TO_PARTICIPATE: 'what_do_i_need_to_participate',
    WHERE_CAN_I_GET_GAME: 'where_can_i_get_game',
    HOW_MANY_DARES_CAN_I_DO: 'how_many_dares_can_i_do',
    HOW_DO_I_VIEW_MY_ACCEPTED_COMPLETED_DARES: 'how_do_i_view_my_accepted_completed_dares',
    HOW_DO_I_COMPLETE_A_DARE: 'how_do_i_complete_a_dare',
    WILL_I_GET_PENALIZED_FOR_FAILING_NOT_COMPLETING_A_DARE: 'will_i_get_penalized_for_failing_not_completing_a_dare',
    HOW_DO_I_ACCESS_MY_REWARDS: 'how_do_i_access_my_rewards',
    IS_THIS_OFFER_AVAILABLE_IN_ALL_REGIONS: 'is_this_offer_available_in_all_regions',
    WHAT_IF_I_HAVE_MORE_QUESTIONS_OR_NEED_HELP: 'what_if_i_have_more_questions_or_need_help',
};
