var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef, memo, useMemo, useState } from 'react';
import { blackCardContext, } from 'root/src/client/v2/common/components/BlackCard/contexts/blackCardContext';
import { twMerge } from 'tailwind-merge';
export const Root = memo(forwardRef((props, ref) => {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    const [isHovered, setIsHovered] = useState(false);
    const contextValue = useMemo(() => {
        return {
            isHovered,
            setIsHovered,
        };
    }, [isHovered]);
    return (<blackCardContext.Provider value={contextValue}>
			<div ref={ref} {...rest} className={twMerge('border-box group flex flex-col flex-initial justify-between bg-black bg-opacity-90 filter drop-shadow-[9px_9px_10px_#000_0.6] m-0_10_20 text-white rounded-2xl relative overflow-hidden transition-0.1s w-[320px] h-[450px] motion-safe:duration-150 hover:shadow-[0_1px_26px_#000] focus:shadow-[0_1px_26px_#000] shadow-2xl', className)} onMouseEnter={() => {
            setIsHovered(true);
        }} onMouseLeave={() => {
            setIsHovered(false);
        }}>
				{children}
			</div>
		</blackCardContext.Provider>);
}));
export const Header = memo(forwardRef((_a, ref) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div ref={ref} className={twMerge('flex flex-initial justify-center items-center relative w-full h-[40%] overflow-hidden', className)} {...props}>
			{children}
		</div>);
}));
export const Separator = memo(forwardRef((_a, ref) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div ref={ref} className={twMerge('flex flex-initial z-10 bg-dark-1', className)} {...props}>
			{children}
		</div>);
}));
export const Body = memo(forwardRef((_a, ref) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div ref={ref} className={twMerge('flex flex-col overflow-hidden h-1/2 mx-4', className)} {...props}>
			{children}
		</div>);
}));
export const Footer = memo(forwardRef((_a, ref) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div ref={ref} className={twMerge('flex flex-initial justify-center items-center h-[20%] px-5 py-4', className)} {...props}>
			{children}
		</div>);
}));
export const Caption = memo(forwardRef((_a, ref) => {
    var { title, className } = _a, props = __rest(_a, ["title", "className"]);
    return (<p ref={ref} className={twMerge('font-mono text-teal font-bold', className)} {...props}>
			{title}
		</p>);
}));
export const HighlightedSection = memo(forwardRef((_a, ref) => {
    var { title, text } = _a, props = __rest(_a, ["title", "text"]);
    return (<div ref={ref} className={twMerge('flex flex-col', props.className)} {...props}>
			<div className="flex flex-col my-1 text-xs text-teal uppercase">{title}
			</div>
			<div className="flex flex-col my-1 text-xs text-light-1">{text}</div>
		</div>);
}));
