import { calculateMaxDaresByPlatform, calculateMaxDaresForBestPlatform, } from 'root/src/shared/util/calculateMaxDares';
import { useDropEvent } from 'root/src/client/logic/dropEvent/hooks/useDropEvent';
import { DarePlatform } from 'root/src/shared/@types/platforms';
import { useUserCreatorData, useCreatorFollowersCountByPlatform, useMaxCreatorFollowerCount, } from 'root/src/client/v2/common/hooks/useUser';
export function useMaxDaresByPlatform({ platform = DarePlatform.Any, dropEvent }) {
    var _a, _b;
    const { connectedPlatforms, averageCcv, } = useUserCreatorData();
    const maxFollowerCount = useMaxCreatorFollowerCount();
    const followerCountByPlatform = useCreatorFollowersCountByPlatform();
    const campaign = (_a = useDropEvent()) !== null && _a !== void 0 ? _a : dropEvent;
    if (!campaign) {
        return 0;
    }
    if (platform === DarePlatform.Any) {
        const bestPlatform = calculateMaxDaresForBestPlatform({
            followerCount: maxFollowerCount || 0,
            averageCcv: averageCcv || 0,
            campaign,
            connectedPlatforms,
        });
        return (_b = bestPlatform === null || bestPlatform === void 0 ? void 0 : bestPlatform.maxDares) !== null && _b !== void 0 ? _b : 0;
    }
    return calculateMaxDaresByPlatform({
        platform,
        averageCcv: averageCcv || 0,
        followerCount: followerCountByPlatform[platform],
        campaign,
    });
}
